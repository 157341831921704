.stories-item-page {
  display: flex;
  gap: 1.25rem;
  height: 100%;

  &__content {
    flex: 1 1 auto;
    min-width: 0;
    width: auto;
  }

  &__preview {
    min-width: 31.25rem;
    max-width: 40.625rem;
    width: auto;
  }

  .page-card {
    padding: 1.5rem;
  }
}
