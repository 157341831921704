.file-drop-area {
  position: relative;
  border: 1px dashed var(--teal-accent);
  animation: wave-glow 1.6s infinite alternate ease-in-out;
  border-radius: 8px;
  transition:
    border-color 0.3s,
    background-color 0.3s,
    box-shadow 0.3s,
    transform 0.3s;
  color: #666;
}

.file-drop-area.drag-over {
  border-color: var(--teal-dark);
  background-color: rgba(76, 175, 80, 0.1);
  animation: none; /* Отключаем анимацию glow */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Добавляем тень */
  transform: translateY(-5px); /* Поднимаем блок ближе к пользователю */
}

.file-drop-area.file-dropped {
  border-color: var(--status-green);
  background-color: rgba(33, 150, 243, 0.1);
  animation: drop-effect 0.6s ease-in-out;
}

@keyframes drop-effect {
  0% {
    box-shadow: 0 0 10px #09b88466;
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 20px #09b88499;
    transform: scale(1.05);
  }
  100% {
    box-shadow: 0 0 0 #09b884;
    transform: scale(1);
  }
}

@keyframes wave-glow {
  0% {
    box-shadow: inset 0 0 10px #4aaaba66;
  }
  50% {
    box-shadow: inset 0 0 30px #4aaaba99;
  }
  100% {
    box-shadow: inset 0 0 10px #4aaaba66;
  }
}
