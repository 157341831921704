@import '../vars/typography';
@import '../vars/palette';

body {
  /* Base text */

  --teal-accent: #4aaaba;
  --teal-dark: #297e8f;
  --teal-light: #effafc;
  --orange: #fa8e51;
  --black-light: #546371;
  --white: #fff;
  --grey-1-bg: #f3f5f6;
  --grey-2-bg: #edeef0;
  --grey-3: #c3c7ce;
  --grey-4: #868686;
  --grey-5: #dae0e7;
  --status-red: #fb5d5e;
  --status-yellow: #f4c958;
  --status-green: #09b884;
  --status-red-light: #ffefef;
  --status-yellow-light: #fef8e8;
  --status-green-light: #eaf8f1;
  --black---primary: #343e48;
  --text-black: #343e48;
  --text-secondary: #868686;
  --text-white: #fff;

  --second-family: "Nexa", sans-serif;

  --theme-primary: #343e48;
  --theme-text-accent: #72879e;
  --theme-text-main: var(--theme-primary);
  --theme-text-secondary: #868686;
  --theme-text-third: #b4b5b5;
  --theme-text-error: #b00020;
  --theme-text-danger: #d52833;
  --theme-text-red: #ff2d3b;

  /* Alerts */
  --theme-alert-danger-bg: #f1dbda;
  --theme-alert-danger-color: #a90e46;

  --theme-alert-info-bg: #e0e0e0;
  --theme-alert-info-color: var(--theme-text-main);

  --theme-alert-success-bg: #d5ecd6;
  --theme-alert-success-color: #1f8b24;

  --theme-alert-warn-bg: #f0e5d7;
  --theme-alert-warn-color: #bf710f;

  --theme-info-panel-color: #818d93;

  /* Layout */
  --theme-main-bg: #edeef0;
  --theme-card-bg: white;
  --theme-lines: #e8e8e8;

  /* Scrollbar */
  --theme-scrollbar-bg: #f6f6f6;
  --theme-scrollbar-thumb: var(--grey-3);

  /* Input */
  --theme-input-border: #cad2da;
  --theme-input-focused: #72879e;
  --theme-input-placeholder: #979797;
  --theme-input-label: var(--theme-text-secondary);
  --theme-input-photo-bg: var(--theme-input-border);

  /** Toolbar */
  --theme-toolbar-bg: var(--theme-primary);
  --theme-toolbar-color: #fefefe;

  /** Sidebar */
  --theme-sidebar-bg: var(--theme-card-bg);
  --theme-sidebar-color: var(--theme-text-secondary);
  --theme-sidebar-active: var(--teal-accent);

  /** Buttons */
  --theme-button-disabled-color: var(--theme-input-border);
  --theme-icon-button-bg: #ffffff;
  --theme-icon-button-color: #5f676b;
  --theme-icon-button-hover-bg: linear-gradient(180deg, #46585c 0%, #0c1517 100%);
  --theme-icon-button-hover-color: var(--theme-icon-button-bg);
  --theme-icon-button-shadow: 0 6px 20px 0 #3838381f;

  --theme-flat-button-border-radius: 3.875rem;
  --theme-flat-button-primary-bg: var(--teal-accent);
  --theme-flat-button-primary-color: var(--theme-card-bg);
  --theme-flat-button-primary-border: var(--teal-accent);
  --theme-flat-button-primary-hover-bg: var(--teal-dark);
  --theme-flat-button-primary-hover-color: var(--theme-flat-button-primary-color);
  --theme-flat-button-primary-hover-border: var(--theme-flat-button-primary-border);
  --theme-flat-button-primary-hover-opacity: 1;
  --theme-flat-button-primary-disabled-bg: var(--grey-3);
  --theme-flat-button-primary-disabled-color: var(--theme-card-bg);
  --theme-flat-button-primary-disabled-border: var(--theme-flat-button-primary-disabled-color);
  --theme-flat-button-primary-disabled-opacity: 0.5;

  --theme-flat-button-secondary-bg: var(--grey-1-bg);
  --theme-flat-button-secondary-color: var(--black-light);
  --theme-flat-button-secondary-border: var(--grey-1-bg);
  --theme-flat-button-secondary-hover-bg: var(--grey-2-bg);
  --theme-flat-button-secondary-hover-color: var(--black-light);
  --theme-flat-button-secondary-hover-border: var(--grey-2-bg);
  --theme-flat-button-secondary-active-bg: var(--theme-flat-button-secondary-hover-bg);
  --theme-flat-button-secondary-active-color: var(--theme-flat-button-secondary-hover-color);
  --theme-flat-button-secondary-active-border: var(--theme-flat-button-secondary-hover-border);
  --theme-flat-button-secondary-disabled-bg: var(--grey-3);
  --theme-flat-button-secondary-disabled-color: var(--white);
  --theme-flat-button-secondary-disabled-border: var(--grey-3);
  --theme-flat-button-secondary-disabled-opacity: 0.5;

  --theme-command-button-background: #72879e;
  --theme-command-button-color: #fff;
  --theme-command-button-hover-bg: var(--theme-primary);
  --theme-command-button-hover-color: #fff;
  --theme-command-button-disabled-bg: #bbbbbb;
  --theme-command-button-disabled-color: #fff;

  /** Table */
  --theme-table-header-bg: #f7f7f7;
  --theme-table-header-color: #111827;
  --theme-table-header-border: #f7f7f7;
  --theme-table-cell-border: var(--theme-input-border);
  --theme-table-row-selected-bg: #f4f4f4;

  /* */
  --theme-user-avatar-bg: #cad2da;

  /* Radio */
  --theme-radio-unselected-border: --teal-accent;
  --theme-radio-selected-border: var(--teal-accent);
  --theme-radio-icon-color: var(--teal-accent);

  /* Pagination */
  --theme-pagination-color: var(--theme-primary);
  --theme-pagination-bg: var(--theme-card-bg);
  --theme-pagination-disabled-color: var(--theme-input-border);
  --theme-pagination-disabled-bg: var(--theme-card-bg);
  --theme-pagination-active-color: var(--theme-card-bg);
  --theme-pagination-active-bg: var(--theme-text-accent);
}
