/* Red */

$palette-red-50: #FFEBEE;
$palette-red-100: #FFCDD2;
$palette-red-200: #EF9A9A;
$palette-red-300: #E57373;
$palette-red-400: #EF5350;
$palette-red-500: #F44336;
$palette-red-600: #E53935;
$palette-red-700: #D32F2F;
$palette-red-800: #C62828;
$palette-red-900: #B71C1C;

$palette-red-A100: #FF8A80;
$palette-red-A200: #FF5252;
$palette-red-A400: #FF1744;
$palette-red-A700: #D50000;

/* Gray */

$palette-gray-50: #FAFAFA;
$palette-gray-100: #F5F5F5;
$palette-gray-200: #EEEEEE;
$palette-gray-300: #E0E0E0;
$palette-gray-400: #BDBDBD;
$palette-gray-500: #9E9E9E;
$palette-gray-600: #757575;
$palette-gray-700: #616161;
$palette-gray-800: #424242;
$palette-gray-900: #212121;

/* Cyan */

$palette-cyan-50: #E9F5F7;
$palette-cyan-100: #C9E6EA;
$palette-cyan-200: #A5D5DD;
$palette-cyan-300: #80C4CF;
$palette-cyan-400: #65B7C4;
$palette-cyan-500: #4AAABA;
$palette-cyan-600: #43A3B3;
$palette-cyan-700: #3A99AB;
$palette-cyan-800: #3290A3;
$palette-cyan-900: #227F94;

/* TES */

$palette-tes-50: #ecedef;
$palette-tes-100: #ced4d9;
$palette-tes-200: #afb7c0;
$palette-tes-300: #8e9ba8;
$palette-tes-400: #778696;
$palette-tes-500: #5f7284;
$palette-tes-600: #526473;
$palette-tes-700: #43505d;
$palette-tes-800: #343e48;
$palette-tes-900: #222a31;
