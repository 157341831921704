.item-page {
  .mat-mdc-tab-group,
  .mat-mdc-tab-body-wrapper {
    height: 100%;
    overflow: auto;
  }
  .mat-mdc-tab-group.mat-mdc-tab-group-dynamic-height .mat-mdc-tab-body.mat-mdc-tab-body-active,
  .mat-mdc-tab-group-dynamic-height .mat-mdc-tab-body-content {
    // overflow-y: auto;
  }
}
