body {
  .mat-mdc-checkbox {
    &.checkbox-reversed {
      .mdc-form-field {
        width: 100%;
        flex-direction: row-reverse;

        > label {
          padding-left: 0;
          padding-right: 0.25rem;
        }
      }
    }

    // --mdc-checkbox-selected-icon-color: var(--teal-accent);
    // --mdc-checkbox-selected-hover-icon-color: var(--teal-dark);
    // --mdc-checkbox-selected-focus-icon-color: var(--teal-dark);
    --mdc-checkbox-selected-checkmark-color: var(--white);

    // --mdc-checkbox-selected-focus-state-layer-opacity: var(--teal-accent);
    // --mdc-checkbox-selected-focus-state-layer-color: var(--teal-accent);
    // --mdc-checkbox-unselected-focus-state-layer-opacity: var(--teal-dark);
    // --mdc-checkbox-unselected-focus-state-layer-color: var(--teal-dark);
  }
  .mat-mdc-list-option
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled
    ~ .mdc-checkbox__background
    .mdc-checkbox__checkmark {
    --mdc-checkbox-selected-checkmark-color: var(--white);
  }
}
