@use '../vars/typography';

.table-page {
  .page-title {
    margin-bottom: 1rem;
  }

  .search {
    width: 16rem;
  }
}
