@font-face {
  font-family: 'Nexa';
  src:
    url('/assets/fonts/Nexa/nexa_bold.woff2') format('woff2'),
    url('/assets/fonts/Nexa/nexa_bold.woff') format('woff'),
    url('/assets/fonts/Nexa/nexa_bold.ttf') format('truetype'),
    url('/assets/fonts/Nexa/nexa_bold.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src:
    url('/assets/fonts/Nexa/nexa_book.woff2') format('woff2'),
    url('/assets/fonts/Nexa/nexa_book.woff') format('woff'),
    url('/assets/fonts/Nexa/nexa_book.ttf') format('truetype'),
    url('/assets/fonts/Nexa/nexa_book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src:
    url('/assets/fonts/Nexa/nexa_book-italic.woff2') format('woff2'),
    url('/assets/fonts/Nexa/nexa_book-italic.woff') format('woff'),
    url('/assets/fonts/Nexa/nexa_book-italic.ttf') format('truetype'),
    url('/assets/fonts/Nexa/nexa_book-italic.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src:
    url('/assets/fonts/Nexa/nexa_light.woff2') format('woff2'),
    url('/assets/fonts/Nexa/nexa_light.woff') format('woff'),
    url('/assets/fonts/Nexa/nexa_light.ttf') format('truetype'),
    url('/assets/fonts/Nexa/nexa_light.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nexa';
  src:
    url('/assets/fonts/Nexa/nexa_regular.woff2') format('woff2'),
    url('/assets/fonts/Nexa/nexa_regular.woff') format('woff'),
    url('/assets/fonts/Nexa/nexa_regular.ttf') format('truetype'),
    url('/assets/fonts/Nexa/nexa_regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
