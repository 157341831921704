.mdc-icon-button {
  &._small {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem !important;
    font-size: 1rem;
    line-height: 1rem;

    .mat-icon {
      width: 1rem;
      height: 1rem;
      font-size: 1rem;
      line-height: 1rem;
    }

    .mat-mdc-button-touch-target {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
