.mdc-button {
  &.mdc-button--outlined,
  &.mdc-button--unelevated {
    &:not(.mat-unthemed) {
      text-transform: uppercase;
      font-weight: 500;
      border-radius: 0;
    }
  }
}

.mat-mdc-icon-button {
  &[disabled][disabled] {
    --mdc-icon-button-icon-color: var(--theme-text-secondary);
    --mdc-icon-button-disabled-icon-color: var(--grey-2-bg);
  }
}
