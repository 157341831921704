@import 'typography';

.page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page-header {
  display: flex;
  align-items: center;
  width: 100%;
  flex: 0 0 auto;
  margin-bottom: 0.75rem;
  // padding: 0 1.875rem;
  padding: 0 0.875rem;
}

.page-title {
  @include typography-h6;
  margin-top: 0;
  margin-bottom: 0;
}

.page-card {
  flex: 1 1 auto;
  width: 100%;
  background: var(--theme-card-bg);
  border-radius: 0.625rem;
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  // overflow: auto;
}

.page-counter {
  font-weight: 500;
  font-size: 0.88rem;
  color: var(--teal-dark);
  margin-bottom: 1rem;

  &._empty {
    font-weight: 400;
    color: var(--theme-text-secondary);
  }
}

.page-toolbar {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.25rem;

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    color: var(--grey-4);
  }
}

.table-page-card {
  .search {
    width: 16rem;
  }
}

.tab-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    flex: 0 0 auto;
    margin-bottom: 1.25rem;
  }

  &__body {
    flex: 1 1 auto;
  }

  &__footer {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
