@import 'typography';

.dialog {
  padding: 1.5rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__title {
    @include typography-h6;
    margin-bottom: 1.25rem;
    min-height: 2rem;
    display: flex;
    align-items: center;
  }

  &__close-wrapper {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    .mat-icon {
      font-size: 2rem;
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
    }

    button {
      width: 2rem;
      height: 2rem;
      padding: 0;
      border-radius: 50%;
      border: 0;
      color: #6e7375;
      background-color: transparent;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: transparent;
        color: #484b4c;
      }
    }
  }

  // &__close-wrapper {
  //   position: absolute;
  //   top: 2rem;
  //   right: 1.75rem;

  //   button {
  //     width: 2.25rem;
  //     height: 2.25rem;
  //     padding: 0;
  //     border-radius: 50%;
  //     background: var(--theme-card-bg);
  //     border: 0;
  //     color: var(--theme-text-secondary);
  //     display: inline-flex;
  //     align-items: center;
  //     justify-content: center;
  //     cursor: pointer;

  //     &:hover {
  //       background: var(--theme-text-main);
  //       color: white;
  //     }
  //   }
  // }

  &__form {
    margin-bottom: 1rem;

    app-tariff-selector {
      margin-bottom: 1.25rem;
    }

    mat-form-field:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    button {
      max-width: 15rem;
      height: 3rem;
    }
  }
}

