@use '@angular/material' as mat;

$sidenav__width: 15rem;
$sidenav__width-collapsed: 5.75rem;
$sidenav__animation-duration: 0.4s;

$sidenav-item__left-padding: 1.5rem;
$sidenav-item__right-padding: 1.31rem;
$sidenav-item__left-padding-step: 0.5rem;

$sidenav-submenu_sel: null;

.sidenav {
  background: var(--theme-sidebar-bg);
}

.sidenav-container {
  overflow: visible !important;
}

.mat-drawer-inner-container {
  overflow: visible !important;
}

.mat-drawer-content {
  transition: margin-left $sidenav__animation-duration cubic-bezier(0.25, 0.8, 0.25, 1) !important;
}

.mat-drawer-side {
  border-right: 0;

  &:not(.open) {
  }

  .sidenav {
    width: $sidenav__width;
    transition: width $sidenav__animation-duration cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  }

  &.sidenav-collapsed.mat-drawer-opened {
    width: $sidenav__width-collapsed;
    transition: width $sidenav__animation-duration cubic-bezier(0.25, 0.8, 0.25, 1) !important;

    .sidenav {
      width: $sidenav__width-collapsed;
      transition: width $sidenav__animation-duration cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    }

    & + .mat-drawer-content,
    & + .cdk-visually-hidden + .mat-drawer-content,
    & + .cdk-visually-hidden + .cdk-visually-hidden + .cdk-visually-hidden + .mat-drawer-content {
      margin-left: $sidenav__width-collapsed !important;
    }

    .header {
      .toggle mat-icon {
        transform: scaleX(-1);
      }
    }

    .menu {
      .sidenav-item {
        // max-width: 2.5rem;
        transition: max-width $sidenav__animation-duration cubic-bezier(0.25, 0.8, 0.25, 1) 40ms;

        .name {
          max-width: 0;
          text-wrap: nowrap;
          overflow: hidden;
          opacity: 0;
          transition: all $sidenav__animation-duration cubic-bezier(0.25, 0.8, 0.25, 1) 40ms;
        }

        .dropdown-indicator {
          width: 0;
          opacity: 0;
          margin: 0;
          transition: all $sidenav__animation-duration cubic-bezier(0.25, 0.8, 0.25, 1) 40ms;
        }

        // .icon,
        // .letter-icon {
        //   transform: translate3d(8px, 0, 0);
        //   transition: transform $sidenav__animation-duration cubic-bezier(0.25, 0.8, 0.25, 1);
        // }
      }

      .sub-menu {
        // .dropdown-indicator {
        //   transform: translate3d(-10px, 0, 0);
        //   transition: transform $sidenav__animation-duration cubic-bezier(0.25, 0.8, 0.25, 1);
        // }
      }
    }

    .footer {
      span {
        max-width: 0;
        opacity: 0;
        overflow: hidden;
        transition: all $sidenav__animation-duration cubic-bezier(0.25, 0.8, 0.25, 1);
      }

    }

    &.open {
      .sidenav-item {
        justify-content: flex-start;
      }

      transform: translate3d(0, 0, 0) !important;

      .header .actions,
      .header .logo {
        transform: translate3d(0, 0, 0);
      }

      .menu {
        .heading {
          .name,
          .toggle {
            transform: translate3d(0, 0, 0);
          }
        }

        .sidenav-item {
          .name {
            opacity: 1;
            width: 14rem;
          }

          .dropdown-indicator {
            opacity: 1;
            width: 1.5rem;
          }
        }

        .sub-menu .dropdown-indicator {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &.open {
    transition: transform $sidenav__animation-duration cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    .sidenav-item {
      .name {
        opacity: 1;
        width: fit-content;
        text-wrap: nowrap;
        max-width: 13rem;
        transition: opacity $sidenav__animation-duration cubic-bezier(0.25, 0.8, 0.25, 1);
      }
    }
  }
}

.sidenav-item-container .sub-menu {
  position: relative;
  padding: 0;
  overflow: hidden;

  .sidenav-item.active {
    color: var(--teal-accent);
    font-weight: 500;
    border-radius: unset;
    background: unset;
  }
}

.sidenav-item-container {
  > .sidenav-item {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 0 $sidenav-item__right-padding 0 $sidenav-item__left-padding;
    color: var(--theme-sidebar-color);

    &:hover {
      color: var(--teal-dark);
      border-radius: 0.5rem;

      .icon {
        color: var(--teal-dark);
      }
    }

    &.active {
      color: var(--theme-sidebar-active);
      background: var(--grey-1-bg);
      font-weight: 500;

      .icon {
        color: var(--teal-dark);
      }
    }
  }

  .sub-menu {
    .sidenav-item {
      // color: var(--theme-sidebar-color);
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.125rem;
    }
  }

  // @for $i from 1 through 6 {
  //   $sidenav-submenu_sel: if($i == 1, "> .sub-menu .sidenav-item-container", selector-nest($sidenav-submenu_sel, "> .sub-menu .sidenav-item-container"));

  //   #{$sidenav-submenu_sel} {
  //     > .sidenav-item {
  //       padding-left: $sidenav-item__left-padding + $sidenav-item__left-padding-step * $i;
  //     }
  //   }
  // }
}
