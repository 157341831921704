@use "vars" as vars;
@forward "vars";

@mixin typography-h1 {
  font-family: vars.$font-family-head;
  font-weight: lighter;
  font-size: 6rem;
}

@mixin typography-h2 {
  font-family: vars.$font-family-head;
  font-weight: lighter;
  font-size: 3.75rem;
}

@mixin typography-h3 {
  font-family: vars.$font-family-head;
  font-weight: 400;
  font-size: 3rem;
}

@mixin typography-h4 {
  font-family: vars.$font-family-head;
  font-weight: 500;
  font-size: 2.125rem;
  line-height: 2.5rem;
}

@mixin typography-h5 {
  font-family: vars.$font-family-head;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

@mixin typography-h6 {
  font-family: vars.$font-family-head;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@mixin typography-h7 {
  font-family: vars.$font-family-head;
  font-weight: 500;
  font-size: 1.125rem;
}

@mixin typography-subtitle-1 {
  font-family: vars.$font-family-head;
  font-weight: 600;
  font-size: 1rem;
}

@mixin typography-subtitle-2 {
  font-family: vars.$font-family-head;
  font-weight: 500;
  font-size: 0.875rem;
}

@mixin typography-body-1 {
  font-family: vars.$font-family-base;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
}

@mixin typography-body-2 {
  font-family: vars.$font-family-base;
  font-weight: 400;
  font-size: 0.875rem;
}

@mixin typography-button {
  font-family: vars.$font-family-base;
  font-weight: 500;
  font-size: 0.75rem;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

@mixin typography-caption {
  font-family: vars.$font-family-base;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
}

@mixin typography-overline {
  font-family: vars.$font-family-base;
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 0.75rem;
}

@mixin typography-input {
  font-family: vars.$font-family-base;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
}
